<template>
  <div>
    <c-tab
      type="horizon"
      :dense="true"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :height="tabHeight"
    >
      <template v-slot:default="tab">
        <chem-components
          :is="tab.component"
          :editable="editable"
          :revEquipmentCd="revEquipmentCd"
          :param="popupParam"
          @insertEquipInfo="updateMode"
          @setEditable="setEditable"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
export default {
  name: 'equipment-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        equipmentNo: '',
        plantName: '',
        plantCd: '',
        processCds: [],
        equipmentTypeName: '',
        equipmentName: '',
        isBom: false,
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'equipmentInfo',
      tabItems: [
        { name: 'equipmentInfo', icon: 'info', label: '설비 기본정보', component: () => import(`${'./equipmentInfo.vue'}`) }
      ],
      addTabItems: [
        { name: 'eq100', icon: 'construction', label: '점검항목', component: () => import(`${'./equipmentItem.vue'}`) },
        { name: 'equipmentInspect', icon: 'construction', label: '정비/점검이력', component: () => import(`${'./equipmentInspect.vue'}`) },
        { name: 'equipmentSpec', icon: 'account_tree', label: '설비 사양', component: () => import(`${'./equipmentSpec.vue'}`) },
        { name: 'equipmentEtc', icon: 'note_alt', label: 'BOM 및 도면', component: () => import(`${'./equipmentEtc.vue'}`) },
        { name: 'equipmentOperationTime', icon: 'alarm', label: '가동중지/고장정보', component: () => import(`${'./equipmentOperationTime.vue'}`) },
        // { name: 'equipmentBreakdown', icon: 'task_alt', label: '고장정보', component: () => import(`${'./equipmentBreakdown.vue'}`) },
      ],
      editable: true,
      revEquipmentCd: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
  },
  watch: {
  },
  methods: {
    init() {
      if (this.popupParam.equipmentCd != '') {
        this.tabItems = this.tabItems.concat(this.addTabItems);
      }
      if (this.popupParam.tabName) {
        this.tab = this.popupParam.tabName;
      } else {
        this.tab = 'equipmentInfo';
      }

      if (this.popupParam.isBom) {
        this.tab = 'equipmentEtc';
      }
    },
    updateMode(_equipmentCd) {
      this.popupParam.equipmentCd = _equipmentCd;
      this.tabItems = this.tabItems.concat(this.addTabItems);
    },
    setEditable(editable, equipmentCd) {
      this.revEquipmentCd = equipmentCd
      this.editable = editable
    },
  }
};
</script>